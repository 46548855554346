import { CurrencyFormat } from '@securitize/commons-i18n-formatter';

export default {
  computed: {
    formatCurrency() {
      return (amount, mainCurrencyIdentifier = 'USD') => {
        const browserLocale = this.getBrowserLocale() || 'en-US';
        const decimals = +(amount || 0) % 1 === 0 ? 0 : 2;
        return new CurrencyFormat(browserLocale, {
          currency: mainCurrencyIdentifier,
          maximumFractionDigits: decimals,
          minimumFractionDigits: decimals,
        }).format(+(amount || 0));
      };
    },
    formatToken() {
      return (amount) => {
        const browserLocale = this.getBrowserLocale() || 'en-US';
        return new CurrencyFormat(browserLocale, {
          isCrypto: true,
          maximumFractionDigits: 0,
          minimumFractionDigits: 16,
          stripDecimalsIfInteger: true,
        }).formatNumber(+(amount || 0));
      };
    },
  },
  methods: {
    getBrowserLocale() {
      return navigator.languages.find(locale => locale.includes('-'));
    },
  },
};
