<template>
  <span>
    <cp-round-select
      v-show="false"
      v-model="roundSelected"
      :controlled-round-id="roundSelected === 'loading'? undefined : roundSelected"
      is-all-round-option-enabled
    />
    <cp-table
      v-if="roundSelected !== 'loading'"
      ref="cpTable"
      checkbox
      mass-selectable
      :filters="params"
      get-data-action="investors/getFundraiseInvestorsList"
      :url-params="urlParams"
      :fields="getTableFields()"
      :data-parser="listCorrection"
      :table-title="$t('investorsFundraise.title')"
      @tableDataUpdated="onTableDataUpdated"
      @onSort="$emit(onSort, $event)"
      @onSelect="onSelect"
      @onSelectAll="onSelectAll"
      @onMassSelect="onMassSelect"
    >
      <template slot="tableHeader">
        <div class="d-flex justify-content-between">
          <div class="p-4 w-50">
            <cp-round-select
              v-model="roundSelected"
              is-all-round-option-enabled
              @input="handleRoundSelect"
            />
          </div>
          <div class="p-4 w-50">
            <div
              v-if="shouldDisplayBulkActions"
              class="input-group"
            >
              <cp-select
                v-model="selectedOperation"
                input-class=""
                :options="bulkOperationOptions"
              />
              <span class="input-group-append">
                <cp-button
                  :disabled="selectedInvestorsCount === 0"
                  size="ms"
                  variant="primary"
                  :is-loading="isBulkLoading"
                  @click="handleBulkOperationConfirmationModals"
                >
                  {{ $t('investorsOnboarding.label.run', [selectedInvestorsCount]) }}
                </cp-button>
              </span>
            </div>
          </div>
          <cp-batch-actions
            :disabled="isEmptyExportList"
            @onExportList="$emit('onExportList')"
            @onEditLabels="$emit('onEditLabels')"
            @onShowLabels="$emit('onShowLabels')"
          />
        </div>
      </template>
      <template
        slot="name"
        slot-scope="{ rowData }"
      >
        <a
          v-if="!rowData.item.isAggregatedBrokerDealerGroup"
          :href="getInvestorDetailsUrl(rowData.item)"
          @click.prevent="$emit('onViewElem', rowData.item, { ...$event })"
        >
          {{ getFullName(rowData.item) }}
        </a>
        <div v-else>
          {{ getFullName(rowData.item) }}
        </div>
      </template>

      <template
        slot="investorStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          v-b-tooltip.hover="true"
          style="cursor:pointer"
          :variant="rowData.item.investStatusStyle"
        >
          {{ rowData.item.investStatusValue }}
        </b-badge>
      </template>

      <template
        slot="lastAmountPledged"
        slot-scope="{ rowData }"
      >
        <span class="d-flex">
          <cp-ellipsis
            :data-value="formatCurrency(rowData.item.lastAmountPledged, issuerMainCurrencyIdentifier)"
            :tooltip-value="formatCurrency(rowData.item.lastAmountPledged, issuerMainCurrencyIdentifier)"
          />
        </span>
      </template>

      <template
        slot="pledgeDate"
        slot-scope="{ rowData }"
      >
        <span class="d-flex">
          {{ rowData.item.pledgeDate | dateFilter }}
        </span>
      </template>

      <template
        slot="lastAmountFunded"
        slot-scope="{ rowData }"
      >
        <span class="d-flex">
          <cp-ellipsis
            :data-value="formatCurrency(rowData.item.lastAmountFunded, issuerMainCurrencyIdentifier)"
            :tooltip-value="formatCurrency(rowData.item.lastAmountFunded, issuerMainCurrencyIdentifier)"
          />
        </span>
      </template>

      <template
        slot="tokensAssigned"
        slot-scope="{ rowData }"
      >
        <cp-ellipsis
          :data-value="formatToken(rowData.item.tokensAssignedToDisplay)"
          :tooltip-value="formatToken(rowData.item.tokensAssignedToDisplay)"
        />
      </template>

      <template
        slot="subscriptionAgreementStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :variant="rowData.item.subscriptionAgreementStatusStyle"
        >
          {{ rowData.item.subscriptionAgreementStatusValue }}
        </b-badge>
      </template>

      <template
        slot="roundIds"
        slot-scope="{ rowData }"
      >
        <div v-if="isRounds(rowData.item)">
          <span
            v-for="round in getRoundsList(rowData.item)"
            :key="round"
          >
            <b-badge
              v-if="round"
              class="align-text-bottom ml-1"
              variant="outline-secondary"
            >
              {{ findNameById(round) }}
            </b-badge>
          </span>
          <span
            v-if="rowData.item.roundIds.length > 3"
            v-b-tooltip.hover="true"
            :title="getRoundsTitle(rowData.item)"
            style="cursor: pointer"
          >
            + {{ rowData.item.roundIds.length - 3 }}
          </span>
        </div>
        <div v-else>
          {{ 'N/A' }}
        </div>
      </template>

      <template
        slot="labels"
        slot-scope="{ rowData }"
      >
        <div v-if="isLabels(rowData.item)">
          <span
            v-for="label in getLabelsList(rowData.item)"
            :key="label"
          >
            <b-badge
              v-if="label"
              class="align-text-bottom ml-1"
              variant="outline-secondary"
            >
              {{ label }}
            </b-badge>
          </span>
          <span
            v-if="rowData.item.labels.length > 3"
            v-b-tooltip.hover="true"
            :title="getLabelsTitle(rowData.item)"
            style="cursor: pointer"
          >
            + {{ rowData.item.labels.length - 3 }}
          </span>
        </div>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            v-if="!rowData.item.isAggregatedBrokerDealerGroup"
            variant="default btn-xs icon-btn md-btn-flat"
            @click="$emit('onViewElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-eye" />
          </cp-button>
          <cp-button
            v-if="!rowData.item.isAggregatedBrokerDealerGroup"
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="$emit('onRemoveElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>
    <cp-confirm-modal
      v-if="selectedOperation && bulkOperations[selectedOperation]"
      ref="cpBulkOperationsConfirmModal"
      :title="bulkOperations[selectedOperation].confirmationModalTitleText"
      :ok-text="bulkOperations[selectedOperation].confirmationModalOkText"
      :cancel-text="conditionalCancelText"
      :is-ok-disabled="!isFboInvestorSelected && selectedOperation === 'sendDocuSignEmail'"
      @onOk="handleBulkOperation"
    >
      <span v-if="isFboInvestorSelected || selectedOperation !== 'sendDocuSignEmail'">
        {{ bulkOperations[selectedOperation].confirmationModalConfirmMessageText }}
        {{ selectedInvestorsNonFboCount > 0 ? bulkOperations[selectedOperation].confirmationModalSecondaryMessageText : '' }}
      </span>
      <span v-if="!isFboInvestorSelected && selectedOperation === 'sendDocuSignEmail'">
        {{ bulkOperations[selectedOperation].confirmationModalMessageNonFbo }}
      </span>
    </cp-confirm-modal>

    <cp-confirm-modal
      v-if="selectedOperation && bulkOperations[selectedOperation]"
      ref="cpRunIssuanceBulkRes"
      :title="bulkOperations[selectedOperation].confirmationModalTitleText"
      :ok-text="$t('common.button.ok')"
      ok-only
    >
      <span>
        {{ $t('investorsFundraise.bulkActions.issuance.confirmModal.confirmMessageBulk') }}
      </span>

    </cp-confirm-modal>


    <cp-bulk-summary-modal
      ref="bulk-summary"
      v-bind="bulkSummary"
      @onDownloadCsv="onDownloadCsv"
    />
    <bulk-summary-modal-docu-sign-email
      ref="dsocuSignEmail"
      :summary-message="docuSignSummaryMessage"
    />
  </span>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import CpBatchActions from '~/pages/_idIssuer/investors/batch-actions';
import CpRoundSelect from '~/components/common/round-select';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpEllipsis from '~/components/common/ellipse';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpBulkSummaryModal from '../../modals/bulk-summary-modal';
import bulkSummaryModalDocuSignEmail from '../../modals/bulk-summary-modal-docu-sign-email';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import {
  statuses,
  investStatusLabels,
  subscriptionStatusLabels,
  tokenStatusLabels,
  tableFields,
  investorTypeList,
  evergreenTableFields,
} from './options';
import GeneralActionsOfInvestorTables from '~/mixins/general-actions-of-investor-tables';
import CpCurrencyFormatterLatest from '../../../../../mixins/currency-formatter-latest';
import routeNames from '~/utilities/routes';

export default {
  name: 'CpFundraiseTable',
  layout: 'main',
  components: {
    CpBatchActions,
    CpRoundSelect,
    CpTable,
    CpButton,
    CpEllipsis,
    CpConfirmModal,
    CpBulkSummaryModal,
    CpSelect,
    bulkSummaryModalDocuSignEmail,
  },
  mixins: [
    CpCurrencyFormatterLatest,
    GeneralActionsOfInvestorTables,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    rounds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statuses,
      investStatusLabels,
      subscriptionStatusLabels,
      tokenStatusLabels,
      tableFields,
      evergreenTableFields,
      roundSelected: 'loading',
      selectedInvestorsCount: 0,
      selectedInvestorsNonFboCount: 0,
      isBulkLoading: false,
      bulkActionFilters: {},
      bulkSummary: {},
      bulkOperationUserIds: [],
      userIds: [],
      excludedUserIds: [],
      selectedOperation: '',
      docuSignSummaryMessage: '',
      isFboInvestorSelected: false,
      BULK_ISSUANCE_AMOUNT: 250,
      IS_ENABLE_SA_COLUMN_IN_FUNDRISE_TABLE: process.env.VUE_APP_ENABLE_SA_COLUMN_IN_FUNDRISE_TABLE === 'true',
    };
  },
  computed: {
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    ...mapGetters('issuersInfo', ['brokerDealerGroupPermissions']),
    params() {
      return {
        'round-id': this.roundSelected,
        tokenId: this.$route.params.tokenId,
        view: 'fundraise',
        'order-field': 'tokens-assigned',
        ...this.filters,
      };
    },
    isEvergreenRound() {
      const selectedRound = this.rounds.find(round => round.id === this.roundSelected);
      return selectedRound.isEvergreenRound;
    },

    isOperatorCanRunIssuance() {
      if (this.authorizationLevel === 'brokerdealer') {
        return this.brokerDealerGroupPermissions && this.brokerDealerGroupPermissions.canIssueTokens;
      }
      return ['megaadmin', 'superadmin', 'admin'].includes(this.authorizationLevel);
    },
    isBulkIssuanceAvailable() {
      return this.isOperatorCanRunIssuance
        && this.roundSelected;
    },
    isSelectedRoundActive() {
      const selectedRound = this.rounds.find(round => round.id === this.roundSelected);
      const isActiveRound = selectedRound && selectedRound.status === 'active';
      return isActiveRound && ['megaadmin', 'brokerdealer'].includes(this.authorizationLevel);
    },
    activeRound() {
      return this.rounds.find(round => round.status === 'active' && round.tokenId === this.params.tokenId);
    },
    bulkOperations() {
      return {
        issueTokens: {
          i18nKey: 'investorsFundraise.bulkActions.issuance.name',
          value: 'issueTokens',
          shouldDisplay: this.isBulkIssuanceAvailable,
          action: this.runBulkIssuance,
          confirmationModalTitleText: this.$t('investorsFundraise.bulkActions.issuance.confirmModal.title'),
          confirmationModalOkText: this.$t('investorsFundraise.bulkActions.issuance.confirmModal.okText'),
          confirmationModalConfirmMessageText: this.$t('investorsFundraise.bulkActions.issuance.confirmModal.confirmMessage', [this.selectedInvestorsCount]),
          bulkSummary: this.showBulkSummaryIssueTokens,
        },
        sendFundingInstructions: {
          i18nKey: 'investorsFundraise.bulkActions.sendFundingInstructions.name',
          value: 'sendFundingInstructions',
          shouldDisplay: this.isSelectedRoundActive,
          action: this.runBulkSendFundingInstructions,
          confirmationModalTitleText: this.$t('investorsFundraise.bulkActions.sendFundingInstructions.confirmModal.title'),
          confirmationModalOkText: this.$t('investorsFundraise.bulkActions.sendFundingInstructions.confirmModal.okText'),
          confirmationModalConfirmMessageText: !this.activeRound ? '' : this.$t('investorsFundraise.bulkActions.sendFundingInstructions.confirmModal.confirmMessage', [this.selectedInvestorsCount, this.activeRound.name]),
          confirmationModalSecondaryMessageText: this.$t('investorsFundraise.bulkActions.sendFundingInstructions.confirmModal.confirmMessageNonFbo', [this.selectedInvestorsNonFboCount]),
          bulkSummaryMessageKey: 'investorsFundraise.bulkActions.sendFundingInstructions.summaryModal.message',
          bulkSummary: this.showBulkSummaryFundingInstructions,
        },
        sendDocuSignEmail: {
          i18nKey: 'investorsFundraise.bulkActions.sendDocuSignEmail.name',
          value: 'sendDocuSignEmail',
          shouldDisplay: this.isSelectedRoundActive,
          action: this.runBulkSendDocuSignEmail,
          confirmationModalTitleText: this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.title'),
          confirmationModalOkText: this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.okText'),
          confirmationModalCancelText: this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.cancelText'),
          confirmationModalConfirmMessageText: !this.activeRound ? '' : this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.confirmMessageAllFbo', [this.selectedInvestorsCount, this.activeRound.name]),
          confirmationModalSecondaryMessageText: this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.confirmMessageSomeFbo', [this.selectedInvestorsNonFboCount]),
          confirmationModalMessageNonFbo: this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.confirmMessageNonFbo', ['DocuSign']),
          bulkSummaryMessage: {
            messageFullSuccess: 'investorsFundraise.bulkActions.sendDocuSignEmail.summaryModal.messageFullSuccess',
            messagePartialSuccess: 'investorsFundraise.bulkActions.sendDocuSignEmail.summaryModal.messagePartialSuccess',
          },
          bulkSummary: this.showBulkSummaryDocuSignEmail,
        },
      };
    },
    bulkOperationOptions() {
      return Object.keys(this.bulkOperations).reduce((acc, key) => {
        const operation = this.bulkOperations[key];
        if (operation && operation.shouldDisplay) {
          const { i18nKey, value } = operation;
          acc = [...acc, { i18nKey, value }];
        }
        return acc;
      }, []);
    },
    shouldDisplayBulkActions() {
      return Boolean(this.bulkOperationOptions.length);
    },
    conditionalCancelText() {
      if (!this.isFboInvestorSelected && this.selectedOperation === 'sendDocuSignEmail') {
        return this.bulkOperations[this.selectedOperation].confirmationModalCancelText;
      }
      return undefined;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.tokenId !== from.params.tokenId) {
        this.roundSelected = 'loading';
        this.selectedInvestorsCount = 0;
      }
    },
    roundSelected(newValue, oldValue) {
      if (newValue !== oldValue) this.resetSelectedOperation();
    },
  },
  created() {
    this.resetSelectedOperation();
  },
  methods: {
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    ...mapActions('investors', ['bulkRunIssuance', 'bulkSendFundingInstructionsEmail', 'bulkSendDocuSignEmail']),
    onSelect({ value, item, selectedItemsCount }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onSelectInvestor', { value, item });
    },
    onSelectAll({ allSelect, selectedItemsCount }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onSelectAll', allSelect);
    },
    onMassSelect({ massSelect, selectedItemsCount }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onMassSelect', massSelect);
    },
    showBulkSummaryModal(data) {
      this.bulkOperations[this.selectedOperation].bulkSummary(data);
    },
    handleRoundSelect(round) {
      this.$emit('input', round);
    },
    getTableFields() {
      const tableFieldsSelected = this.isEvergreenRound
        ? evergreenTableFields
        : tableFields;

      if (this.IS_ENABLE_SA_COLUMN_IN_FUNDRISE_TABLE) {
        return tableFieldsSelected;
      }
      return tableFieldsSelected.filter(field => field.key !== 'subscriptionAgreementStatus');
    },
    findNameById(id) {
      const round = this.rounds.find(item => item.id === +id);
      return round ? round.name : '';
    },
    onTableDataUpdated({ totalItems }) {
      this.checkEmptyTable(totalItems);
    },
    getRoundsList(item) {
      return (item.roundIds || []).filter(val => val).slice(0, 3);
    },
    getRoundsTitle(item) {
      return (item.roundIds || []).filter(val => val).slice(3).map(val => this.findNameById(val)).join('\n');
    },
    isRounds(item) {
      return (item.roundIds || []).length && item.roundIds.filter(val => val).length;
    },
    handleBulkOperationConfirmationModals() {
      if (this.selectedOperation === 'sendFundingInstructions' || this.selectedOperation === 'sendDocuSignEmail') {
        const { selectedItems } = this.getSelectedItems();
        this.selectedInvestorsNonFboCount = selectedItems.filter(({ investorType }) => !this.isFboInvestorType(investorType)).length;
        this.isFboInvestorSelected = Boolean(selectedItems.find(({ investorType }) => this.isFboInvestorType(investorType)));
      }
      this.$refs.cpBulkOperationsConfirmModal.show();
    },
    prepareBulkOperationData() {
      this.bulkActionFilters = this.getTableFilters();
      const { selectedItems, excludedItems } = this.getSelectedItems();
      this.userIds = selectedItems.map(({ id }) => id);
      this.excludedUserIds = excludedItems.map(({ id }) => id);
    },
    handleBulkOperation() {
      this.isBulkLoading = true;
      this.prepareBulkOperationData();
      this.bulkOperationUserIds = this.userIds.length ? this.userIds : null;
      const body = {
        userIds: this.bulkOperationUserIds,
        exceptInvestorsIds: this.excludedUserIds.length ? this.excludedUserIds : null,
        ...this.bulkActionFilters,
      };
      const bulkOperation = this.bulkOperations[this.selectedOperation];
      const bulkOperationAction = bulkOperation && bulkOperation.action;
      bulkOperationAction({ issuerId: this.$route.params.idIssuer, body })
        .then((res) => {
          this.showBulkSummaryModal(res);
        })
        .finally(() => {
          this.isBulkLoading = false;
        });
    },
    async runBulkIssuance({ issuerId, body }) {
      const selectedRound = this.rounds.find(round => round.id === this.roundSelected);
      if (!selectedRound.isEvergreenRound && !selectedRound.issuanceDate) {
        this.CALL_ERROR_TOASTER(this.$t('investorsFundraise.issuanceConfirmModal.errorMessage'));
        return;
      }
      try {
        const { data } = await this.bulkRunIssuance({ issuerId, body });

        return data;
      } catch (err) {
        if (err.response && (err.response.status === 408 || err.response.status === 504)) {
          this.CALL_ERROR_TOASTER(this.$t('store.toaster.investors.bulkRunIssuanceErrorTimeout'));
        }
        throw err;
      }
    },
    runBulkSendFundingInstructions({ issuerId, body }) {
      const { selectedItems } = this.getSelectedItems();
      const fboUserIds = selectedItems.filter(item => this.isFboInvestorType(item.investorType)).map(({ id }) => id);
      const sendFundingInstructionsBody = { ...body, userIds: fboUserIds };
      return this.bulkSendFundingInstructionsEmail({ issuerId, body: sendFundingInstructionsBody });
    },
    runBulkSendDocuSignEmail({ issuerId, body: { roundId, tokenId } }) {
      const { selectedItems } = this.getSelectedItems();
      const fboUserIds = selectedItems.filter(item => this.isFboInvestorType(item.investorType)).map(({ id }) => id);
      const body = { tokenId, roundId, userIds: fboUserIds };
      return this.bulkSendDocuSignEmail({ issuerId, body });
    },
    isFboInvestorType(investorType) {
      return ['fbo-individual', 'fbo-entity'].includes(investorType);
    },
    onDownloadCsv(csvFiles) {
      if (csvFiles.length > 1) {
        this.zipFiles(csvFiles);
      } else {
        const file = csvFiles[0];
        if (file) {
          const { content } = file;
          saveAs(new Blob([atob(content)]), file.name);
        }
      }
    },
    zipFiles(csvFiles) {
      const zip = new JSZip();
      csvFiles.forEach((file) => {
        zip.file(file.name, atob(file.content));
      });

      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, `Funding_Instructions_${moment().format('YYYYMMDD')}`);
        });
    },
    resetSelectedOperation() {
      this.selectedOperation = Boolean(this.bulkOperationOptions.length) && this.bulkOperationOptions[0].value;
    },
    showBulkSummaryIssueTokens(data) {
      if (data.msg && data.msg === 'ISSUANCE_SENT_TO_BULK') this.$refs.cpRunIssuanceBulkRes.show();
      else {
      this.bulkSummary = {
        ...data,
        showDownloadCsv: false,
        customSummaryMessageKey: this.bulkOperations[this.selectedOperation].bulkSummaryMessageKey,
      };
      this.$refs['bulk-summary'].show();
      }
    },
    showBulkSummaryFundingInstructions(data) {
      this.bulkSummary = {
        ...data,
        showDownloadCsv: data.csvFiles && data.csvFiles.length > 0,
        customSummaryMessageKey: this.bulkOperations[this.selectedOperation].bulkSummaryMessageKey,
      };
      this.$refs['bulk-summary'].show();
    },
    showBulkSummaryDocuSignEmail(data) {
      const { bulkSummaryMessage } = this.bulkOperations.sendDocuSignEmail;
      const message = data.errors.length ? bulkSummaryMessage.messagePartialSuccess : bulkSummaryMessage.messageFullSuccess;
      this.docuSignSummaryMessage = this.$t(message, [data.successfulActions, data.errors.length + data.successfulActions, data.errors.length]);
      this.$refs.dsocuSignEmail.show();
    },
    listCorrection(data) {
      return data.map((item) => {
        let correctedItem = {
          ...item,
          investStatusStyle: this.getInvestStatusStyle(item),
          investStatusValue: this.getInvestStatusValue(item),
          investorTypeValue: this.getInvestorTypeValue(item),
          subscriptionAgreementStatusValue: this.getSubscriptionAgreementStatusToDisplay(item),
          subscriptionAgreementStatusStyle: this.getSubscriptionAgreementStatusStyle(item),
          tokenStatusStyle: this.getTokenStatusStyle(item),
          tokenStatusValue: this.geTokenStatusToDisplay(item),
        };

        if (item.brokerDealerGroupName) {
          correctedItem = {
            ...correctedItem,
            name: item.brokerDealerGroupName,
            investStatusValue: 'N/A',
            investorTypeValue: 'N/A',
            subscriptionAgreementStatusValue: 'N/A',
            tokenStatusValue: 'N/A',
            isAggregatedBrokerDealerGroup: true,
          };
        }

        return correctedItem;
      });
    },
    getInvestStatusStyle(item) {
      const colors = {
        'N\\A': 'secondary',
        issued: 'success',
        ready: 'success',
      };
      return colors[item.investorStatus] || 'warning';
    },
    getInvestStatusValue(item) {
      return this.$t(this.investStatusLabels[item.investorStatus]);
    },
    getInvestorTypeValue(item) {
      return this.$t(investorTypeList[item.investorType]);
    },
    getSubscriptionAgreementStatusStyle(item) {
      return statuses.subscription[item.subscriptionAgreementStatus];
    },
    getSubscriptionAgreementStatusToDisplay(item) {
      return this.$t(subscriptionStatusLabels[item.subscriptionAgreementStatus]);
    },
    getTokenStatusStyle(item) {
      return statuses.token[item.tokenStatus];
    },
    geTokenStatusToDisplay(item) {
      return this.$t(tokenStatusLabels[item.tokenStatus]);
    },
    getInvestorDetailsUrl({ id: investorId }) {
      const { params: { idIssuer, tokenId }, query: { search } } = this.$route;
      return routeNames.viewDetails(idIssuer, tokenId, investorId, 'fundraise', search);
    },
  },
};
</script>
