export const statuses = {
  subscription: {
    signed: 'success',
    confirmed: 'success',
    approved: 'success',
    none: 'secondary',
    presented: 'primary',
    rejected: 'danger',
    requested: 'primary',
    sent: 'primary',
    'pre-signed': 'primary',
  },
  token: {
    issued: 'success',
    'not-issued': 'danger',
  },
  investorStatuses: {
    'no-kyc': 'warning',
    'no-subscription-agreement': 'warning',
    ready: 'success',
  },
};

export const investStatusLabels = {
  'N\\A': 'common.label.na',
  ready: 'investorsFundraise.status.investor.ready',
  'pending-kyc': 'investorsFundraise.status.investor.pendingKyc',
  'pending-qualification': 'investorsFundraise.status.investor.pendingQualification',
  'pending-accreditation': 'investorsFundraise.status.investor.pendingAccreditation',
  'pending-funding': 'investorsFundraise.status.investor.pendingFunding',
  'pending-investor-signature': 'investorsFundraise.status.investor.pendingInvestorSignature',
  'pending-issuer-signature': 'investorsFundraise.status.investor.pendingIssuerSignature',
  'pending-funded': 'investorsFundraise.status.investor.pendingFunded',
  issued: 'investorsFundraise.status.investor.issued',
};

export const subscriptionStatusLabels = {
  'N\\A': 'common.label.na',
  signed: 'investorsFundraise.status.subscriptionAgreement.signed',
  confirmed: 'investorsFundraise.status.subscriptionAgreement.confirmed',
  approved: 'investorsFundraise.status.subscriptionAgreement.approved',
  none: 'investorsFundraise.status.subscriptionAgreement.none',
  presented: 'investorsFundraise.status.subscriptionAgreement.presented',
  rejected: 'investorsFundraise.status.subscriptionAgreement.rejected',
  requested: 'investorsFundraise.status.subscriptionAgreement.requested',
  sent: 'investorsFundraise.status.subscriptionAgreement.sent',
  'pre-signed': 'investorsFundraise.status.subscriptionAgreement.preSigned',
};

export const tokenStatusLabels = {
  'N\\A': 'common.label.na',
  issued: 'investorsFundraise.status.tokenStatus.issued',
  'not-issued': 'investorsFundraise.status.tokenStatus.notIssued',
};

export const investorTypeList = {
  individual: 'common.category.investorType.individual',
  entity: 'common.category.investorType.entity',
  'fbo-individual': 'common.category.investorType.fboIndividual',
  'fbo-entity': 'common.category.investorType.fboEntity',
};

export const filterLists = {
  investorType: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'common.category.investorType.individual', value: 'individual' },
    { i18nKey: 'common.category.investorType.entity', value: 'entity' },
    { i18nKey: 'common.category.investorType.fboIndividual', value: 'fbo-individual' },
    { i18nKey: 'common.category.investorType.fboEntity', value: 'fbo-entity' },
  ],
  investorStatus: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsFundraise.option.investorStatus.ready', value: true },
    { i18nKey: 'investorsFundraise.option.investorStatus.inProgress', value: false },
  ],
  subscriptionAgreement: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.none', value: 'none' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.requested', value: 'requested' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.sent', value: 'sent' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.signed', value: 'signed' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.confirmed', value: 'confirmed' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.rejected', value: 'rejected' },
    { i18nKey: 'investorsFundraise.option.subscriptionAgreement.preSigned', value: 'pre-signed' },
  ],
};

export const tableFields = [
  { key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle' },
  {
    key: 'name', i18nKey: 'investorsFundraise.label.table.investorName', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'investorStatus', i18nKey: 'investorsFundraise.label.table.investorStatus', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastAmountPledged', i18nKey: 'investorsFundraise.label.table.lastAmountPledged', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'pledgeDate', i18nKey: 'investorsFundraise.label.table.pledgeDate', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'subscriptionAgreementStatus', i18nKey: 'investorsFundraise.label.table.subscriptionAgreementStatus', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastAmountFunded', i18nKey: 'investorsFundraise.label.table.lastAmountFunded', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'tokensAssigned', i18nKey: 'investorsFundraise.label.table.tokensAssigned', sortable: true, tdClass: 'align-middle',
  },
  { key: 'roundIds', i18nKey: 'investorsFundraise.label.table.roundIds', tdClass: 'align-middle' },
  { key: 'labels', i18nKey: 'investorsFundraise.label.table.labels', tdClass: 'text-nowrap align-middle text-center' },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
];

export const evergreenTableFields = [
  { key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle' },
  {
    key: 'name', i18nKey: 'investorsFundraise.label.table.investorName', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'investorStatus', i18nKey: 'investorsFundraise.label.table.investorStatus', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastAmountPledged', i18nKey: 'investorsFundraise.label.table.lastAmountPledged', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'pledgeDate', i18nKey: 'investorsFundraise.label.table.pledgeDate', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastAmountFunded', i18nKey: 'investorsFundraise.label.table.lastAmountFunded', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'subscriptionAgreementStatus', i18nKey: 'investorsFundraise.label.table.subscriptionAgreementStatus', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'tokensAssigned', i18nKey: 'investorsFundraise.label.table.tokensAssigned', sortable: true, tdClass: 'align-middle',
  },
  { key: 'roundIds', i18nKey: 'investorsFundraise.label.table.roundIds', tdClass: 'align-middle' },
  { key: 'labels', i18nKey: 'investorsFundraise.label.table.labels', tdClass: 'text-nowrap align-middle text-center' },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
];
