<template>
  <cp-filter
    ref="cpFilter"
    name="investorsFundraise"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="country-code"
          :label="$t('investorsFundraise.label.filter.country')"
          :options="countriesList"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="investor-type"
          :label="$t('investorsFundraise.label.filter.investorType')"
          :options="filterLists.investorType"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="investor-status"
          :label="$t('investorsFundraise.label.filter.investorStatus')"
          :options="filterLists.investorStatus"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-range
          name="from-amount-pledged"
          :label="$t('investorsFundraise.label.filter.amountPledged')"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-range
          name="from-amount-funded"
          :label="$t('investorsFundraise.label.filter.amountFunded')"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-range
          name="from-assigned-tokens"
          :label="$t('investorsFundraise.label.filter.tokensAssigned')"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="subscription-agreement-status"
          :label="$t('investorsFundraise.label.filter.subscriptionAgreementStatus')"
          :options="filterLists.subscriptionAgreement"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>
import { mapGetters } from 'vuex';

import { filterLists } from './options';

import CpFilter from '~/components/common/cp-filter';
import CpRange from '~/components/common/standalone-components/inputs/cp-range';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

export default {
  name: 'FundraiseFilter',
  components: {
    CpRange,
    CpFilter,
    CpSelect,
  },
  data() {
    return {
      filterLists,
    };
  },
  computed: {
    ...mapGetters('global', ['countries']),
    countriesList() {
      return [
        { text: this.$t('common.label.all'), value: null },
        ...(this.countries.map(
          country => ({ text: country.name, value: country.countryCode }),
        )),
      ];
    },
  },
  methods: {
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
  },
};
</script>


<style lang="scss">
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
