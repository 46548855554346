<template>
  <div class="round-select">
    <cp-select
      ref="cp-round-select"
      v-model="relevantRoundId"
      :input-class="inputClass"
      :label="label"
      :options="possibleRounds"
      :disabled="disabled"
      @input="onChangeHandle"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  components: {
    CpSelect,
  },
  props: {
    controlledRoundId: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: [String, Number],
      default: null,
    },
    inputClass: {
      type: [String, Number],
      default: null,
    },
    isAllRoundOptionEnabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRound: undefined,
    };
  },
  computed: {
    ...mapGetters('rounds', ['rounds', 'roundsOption']),
    roundsByTokenId() {
      return this.rounds.filter(({ tokenId }) => tokenId === this.$route.params.tokenId);
    },
    relevantRoundId: {
      get() {
        if (this.selectRound !== undefined) {
          return this.selectRound || '';
        }
        if (this.controlledRoundId !== undefined) {
          return this.controlledRoundId || '';
        }
        return (this.lastOrActiveRound && this.lastOrActiveRound.id) || (this.roundsByTokenId[0] && this.roundsByTokenId[0].id) || undefined;
      },
      set(selectRound) {
        this.selectRound = selectRound;
      },
    },
    lastOrActiveRound() {
      return this.activeRound || this.lastRound;
    },
    possibleRounds() {
      const roundsByTokenId = this.roundsOption.filter(({ tokenId }) => tokenId === this.$route.params.tokenId);
      return this.isAllRoundOptionEnabled ? [{ value: '', text: 'All Rounds' }, ...roundsByTokenId] : roundsByTokenId;
    },
    activeRound() {
      return this.roundsByTokenId.find(item => item.status === 'active');
    },
    lastRound() {
      const lastRelevantRound = this.roundsByTokenId.filter(item => moment(item.startsAt).unix() < moment(+new Date()).add(1, 'days').unix());
      return lastRelevantRound.sort((a, b) => moment(a.startsAt).unix() - moment(b.startsAt).unix()).pop();
    },
  },
  watch: {
    relevantRoundId: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.onChangeHandle(val);
        }
      },
      immediate: true,
    },
    lastOrActiveRound: {
      handler(round, oldRound) {
        if ((round && round.id) !== (oldRound && oldRound.id)) this.$emit('onLastOrActiveRoundChange', round.id);
      },
      deep: true,
      immediate: true,
    },
    $route: {
      handler() {
        this.selectRound = undefined;
      },
    },
  },
  methods: {
    onChangeHandle(round) {
      const isReady = !!this.rounds.length || round || round === '';
      if (isReady) {
        this.$emit('input', round === '' ? undefined : round);
      }
    },
  },
};
</script>

<style scoped>

</style>
