<template>
  <cp-general-modal
    ref="bulkSummaryModal"
    :title="this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.title')"
    :message="currentSummaryMessage"
    :ok-text="this.$t('investorsFundraise.bulkActions.sendDocuSignEmail.confirmModal.okText')"
    @onOk="hide"
  />
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'CpBulkSummaryModal',
  components: {
    CpGeneralModal,
  },
  props: {
    summaryTitle: {
      type: String,
      default: '',
    },
    summaryMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentSummaryMessage: '',
    };
  },
  watch: {
    summaryMessage: {
      handler(newVal) {
        this.currentSummaryMessage = newVal;
      },
    },
  },
  methods: {
    show() {
      this.$refs.bulkSummaryModal.show();
    },
    hide() {
      this.$refs.bulkSummaryModal.hide();
    },
  },
};
</script>

<style scoped>

</style>
